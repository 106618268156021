* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 18px;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  min-height: 100vh;
}

.navbar {
  display: flex;
  justify-content: center;
  padding: 1.25rem 0;
}

.navbar-brand {
  width: 8rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 145px - 64px);
  padding: 0 1rem;
}

.card {
  background-color: #fffd46;
  max-width: 400px;
  border-radius: 20px;
  margin: 35px 0;
  padding: 32px 24px;
  color: black;
}

.card-title {
  font-size: 44px;
}

.card-description {
  font-size: 16px;
  margin: 16px 0;
}

.form-helper {
  margin: 1rem 0;
  font-size: 16px;
  font-weight: bold;
}

.form-group {
  position: relative;
}

.form-label {
  position: absolute;
  top: 0.75rem;
  left: 20px;
  font-size: 12px;
  font-weight: bold;
}

.form-input {
  width: 100%;
  padding: 28px 12px 12px 12px;
  font-size: 24px;
  font-weight: bold;
  text-align: right;
  border-radius: 0.25rem;
  border: 1px solid black;
}

.form-input::-webkit-outer-spin-button,
.form-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-input[type="number"] {
  -moz-appearance: textfield;
}

.form-footer {
  margin-top: 2rem;
}

.form-btn {
  border: none;
  border-radius: 30px;
  background-color: black;
  border: 1px solid black;
  color: white;
  width: 100%;
  font-size: 16px;
  padding: 13px;
  cursor: pointer;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
  height: unset;
  line-height: unset;
}

.form-btn[disabled] {
  cursor: not-allowed;
}

.form-btn--secondary {
  margin-top: 1rem;
  background-color: transparent;
  color: black;
}

.form-action {
  display: block;
  text-align: center;
  margin-top: 0.75rem;
  font-weight: bold;
  font-size: 16px;
}

.footer-wrapper {
  width: 100%;
  padding: 0 1rem;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0.75rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-left,
.footer-right {
  width: 100%;
  text-align: center;
}

.footer-left {
  font-size: 0.875rem;
  color: white;
  opacity: 0.8;
  order: 2;
  margin: 0.5rem 0;
}

.footer-right {
  order: 1;
}

.footer-link {
  color: white;
  width: 2rem;
  height: 2rem;
  display: inline-block;
  font-size: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.snowflakes {
  position: absolute;
  left: 0px;
  top: 0px;
}

.snowflake {
  padding-left: 15px;
  font-family: Cambria, Georgia, serif;
  font-size: 14px;
  line-height: 24px;
  position: fixed;
  color: #ffffff;
  user-select: none;
  z-index: 1000;
  pointer-events: none;
}

.snowflake:hover {
  cursor: default;
}

@media (min-width: 768px) {
  .footer {
    max-width: 768px;
    flex-direction: row;
  }

  .footer-left,
  .footer-right {
    width: 50%;
  }

  .footer-left {
    text-align: left;
    margin: 0;
    order: 1;
  }

  .footer-right {
    text-align: right;
    order: 2;
  }
}

@media (min-width: 992px) {
  .footer {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .footer {
    max-width: 1200px;
  }
}

.Toastify__toast-body {
  font-size: 16px;
}

.Toastify__toast-icon {
  margin-right: 1rem;
}
